<template>
<div>

<section id="AboutUs" class="ma-0 mx-auto py-5 primary" style="color: #ddd" >
<v-container>
  <div class="titleDiv px-5" style="border-left: 10px solid #ddd;">
    <p class="display-1">About Us</p>
  </div>
  <div class="contentCard">
  <div class="my-5 py-5">
  <p class="subtitle-1">"We are extremely proud of our most welcoming academy in the state where we strive to ensure that all our students reach their potential.
  We have highly experienced teachers & trainers who have huge amount of exposure and experience working with Multi-national companies, Colleges, Foreign countries & Schools. They have trained over 1000 students who have been successful in their career.
  We provide every student a happy, engaging and thriving environment where students feel home-like. We offer versatile learning experiences which prepare our students for an ever changing world and to become highly competent. 
  We look forward in working effectively in partnership with each one you to provide the best learning opportunities for everyone.
  We look forward to welcoming you to visit to us in action."</p>
 
  <p class="text-right subtitle-2"> - Team Kalalaya</p>
  </div>
  </div>
</v-container>
</section>

</div>
</template>

<script>
export default {
    
    name: 'aboutus'

}
</script>

<style scoped>
#AboutUs{
  margin-top: 0;
  margin-bottom: 7rem;
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
}
</style>